import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authService: AuthService, private location: Location) {
    }

    canActivate(router: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const currentUser = this.authService.getUser();

        if (currentUser) {
            return true;
        }

        const config = {
            queryParams: { returnUrl: state.url }
        };

        this.router.navigate(['/auth/login'], config);

        return false;

    }
}
