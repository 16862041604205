import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


import { Area } from './area.class';
import { ApiCrudService } from '../../../../../../library/src/services/api/crud/api-crud.service';
import { ConfigService } from '../../../../../../library/src/config/config.service';

@Injectable({
    providedIn: 'root'
})
export class AreaService extends ApiCrudService<Area> {

    resourcePath = 'areas';
    resourceType = Area;

    constructor(public http: HttpClient,
                public configService: ConfigService) {
        super(http, configService);
    }

}
